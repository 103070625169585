import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular-ivy";

Sentry.init({
  dsn: "https://c8875f353a0508e0b523a356d4b35f9d@o4506976641351680.ingest.us.sentry.io/4507106277457920",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.disableLogging) {
  function domContentLoaded() {
    'use strict';

    setTimeout(
      console.log.bind(
        console,
        '\n%cStop!',
        'color:red;font-size:50px;font-weight:bold;text-shadow: 1px 1px 0px black, 1px -1px 0px black, -1px 1px 0px black, -1px -1px 0px black;'
      )
    );
    setTimeout(
      console.log.bind(
        console,
        'This is a browser feature intended for developers. If someone told you to copy and paste something here, it is a scam and will give them access to your %caccount',
        'color:red;',
        "etc. so avoid to do that if you are not a developer and doesn't have an understanding of what you are copy and pasting here."
      )
    );
    window.console.log = function () {};
    window.console.error = function () {};
    window.console.info = function () {};
    window.console.warn = function () {};
  }
  document.addEventListener('DOMContentLoaded', domContentLoaded);
  enableProdMode();

  // Block inspect element
  document.onkeydown = function (e) {
    if (e.keyCode == 123) {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
      return false;
    }
    if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
      return false;
    }
  };
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
