<ion-header mode="md" class="one3d-lhs-panel-header">
  <!--brand-logo-->
  <div class="one3d-brand-logo" *ngIf="!globalService.configurator && !globalService.isFeatureView">
    <!-- (click)="redirectToJeep()" -->
    <img src="assets/img/icons/one3d-jeep-logo.svg" alt="Jeep Logo" />
  </div>
  <!--brand-logo-->
  <!-- Feature Mobile View Back -->
  <button expand="full" class="one3d-ef-close-btn mobile-view" slot="end" [disabled]="isFeaturePlaying" *ngIf="globalService.isFeatureView" (click)="closeFeatureView()">
    <span>
      <img src="assets/img/icons/one3d-arrow-back-white.svg" alt="arrow back white" />
      Back
    </span>
  </button>
  <!-- Feature Mobile View Back -->
  <!--features-button-->
  <div class="one3d-header-buttons-rhs">
    <button
      class="one3d-previous-arrow"
      (click)="backToVisualizer()"
      *ngIf="globalService.configurator"
    >
      <span
        ><img src="assets/img/icons/one3d-previous-arrow.svg" alt="back"
      /></span>
      <span translate>CONFIGURATOR_HEADER.FEATURES</span>
    </button>
    <div *ngIf="!globalService.configurator"></div>
    <!--features-button-->
    <!--postcode-->
    <button
      class="one3d-lhs-panel-header-btn one3d-lhs-panel-login-btn one3d-postcode-title"
      (click)="openLoginModal()"
    >
      <span
        ><img src="assets/img/icons/map-pointer.svg" alt="map pointer"
      /></span>
      <span>{{
        globalService.postcode ? globalService.postcode : "POSTCODE"
      }}</span>
    </button>
    <!--postcode-->
    <!--profile functionality is on hold as of now-->
    <!-- <button
      class="one3d-lhs-panel-header-btn one3d-lhs-panel-login-btn one3d-postcode-title one3d-profile-title"
    >
      <span
        ><img
          src="assets/img/icons/one3d-user-profile-icon.svg"
          alt="user profile icon"
      /></span>
      <span>Profile</span>
    </button>
    <div class="one3d-profile-tooltip" style="display: none">
      <div class="one3d-profile-tooltip-content">
        Your configuration has been successfully saved.
        <span class="one3d-close-profile-tooltip">
          <img
            src="assets/img/icons/one3d-black-cross-x.svg"
            alt="black cross x"
          />
        </span>
      </div>
    </div> -->
  </div>
  <!--profile-->

  <!--<button
    class="one3d-lhs-panel-header-btn one3d-lhs-panel-login-btn"
    (click)="openLoginModal()"
    *ngIf="!globalService.customerInfo?.first_name">
    {{ loginHeaderText }}
  </button>-->
  <!-- <button *ngIf="globalService.customerInfo?.first_name" (click)="openProfilePanel()" class="one3d-lhs-panel-header-btn one3d-lhs-panel-profile-btn one3d-lhs-panel-profile-btn-profile">
    <span>
      <img src="assets/img/icons/one3d-profile-icon.svg" alt="profile-icon" />
    </span>
    {{ globalService.customerInfo?.first_name }}
  </button> -->
</ion-header>
