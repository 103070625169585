import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/API/api.service';
import { EventService } from 'src/app/services/Events/events.service';
import { CachingService } from 'src/app/services/cache/caching.service';
import { GlobalService } from 'src/app/services/global/global.service';
import { WidgetsService } from 'src/app/services/ui/widgets.service';

@Component({
  selector: 'app-postcode',
  templateUrl: './postcode.component.html',
  styleUrls: ['./postcode.component.css'],
})
export class PostcodeComponent implements OnInit {
  @Input() contentData: any;
  @Input() type: any;

  ausPostcodeRegex = /^(0[1-9][0-9]{2}|[1-9][0-9]{3})$/;

  formErrors = {
    postcode: '',
  };

  validationMessages = {
    postcode: {
      required: '*To go ahead, please enter the postcode.',
      pattern:
        'Sorry, but the postcode appears to be invalid. Please correct it before proceeding.',
    },
  };

  postcodeForm: UntypedFormGroup;
  postcodeFormSubscription: Subscription;

  constructor(
    private modalController: ModalController,
    public formBuilder: UntypedFormBuilder,
    public globalService: GlobalService,
    private cachingService: CachingService,
    private events: EventService,
    private apiService: ApiService,
    private widgetsService: WidgetsService
  ) {}

  ngOnInit() {
    this.setPostCodeForm();
  }

  ngOnDestroy() {
    this.postcodeFormSubscription.unsubscribe();
  }

  setPostCodeForm() {
    this.postcodeForm = this.formBuilder.group({
      postcode: [
        null,
        {
          validators: [
            Validators.pattern(this.ausPostcodeRegex),
            Validators.required,
          ],
          updateOn: 'change',
        },
      ],
    });

    this.postcodeFormSubscription = this.postcodeForm.valueChanges.subscribe(
      () => this.onValueChanged('postcodeForm')
    );
    this.onValueChanged('postcodeForm');
  }

  async submitPostcode() {
    // for now postcode based price is not integrated, no data from client side
    if (this.postcodeForm.valid) {
      const postcodeFormSubmit = this.postcodeForm.value;
      this.widgetsService.showLoader();
      this.globalService
        .getVariantAll(postcodeFormSubmit.postcode)
        .then(async (res: any) => {
          this.widgetsService.hideLoader();
          // await this.cachingService.setDataInLocal(
          //   'd_id',
          //   res.response.variants[0].dealer_ship_data
          // );
          await this.cachingService.setDataInLocal(
            'p_cd',
            postcodeFormSubmit.postcode
          );
          this.events.publish('events', {
            updatedPostcode: postcodeFormSubmit.postcode,
          });
          await this.cachingService
            .setDataInSession('variants', res.response)
            .then(() => {
              this.closeModal({ type: this.type, res });
            });
          // for first timer submit or resubmit after edit, different event will be triggered on success & fail
          if (this.globalService.postcode) {
            this.apiService.logGAEvent(
              'uaevent',
              'Content',
              'Click::Customer_Details',
              'postcode resubmit pass'
            );
          } else {
            this.apiService.logGAEvent(
              'uaevent',
              'Content',
              'Click::Customer_Details',
              'postcode submit pass'
            );
          }
        })
        .catch((err) => {
          this.widgetsService.hideLoader();
          this.formErrors.postcode = this.validationMessages.postcode.pattern;
          this.widgetsService.toastWithButtons(err.error.message, 'danger');
          if (this.globalService.postcode) {
            this.apiService.logGAEvent(
              'uaevent',
              'Content',
              'Click::Customer_Details',
              'postcode resubmit fail'
            );
          } else {
            this.apiService.logGAEvent(
              'uaevent',
              'Content',
              'Click::Customer_Details',
              'postcode submit fail'
            );
          }
        });
    } else {
      console.error('postcode form not valid');
    }
  }

  onValueChanged(formName) {
    if (!this[formName]) {
      return;
    }
    const form = this[formName];
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key];
            }
          }
        }
      }
    }
  }

  closeModal(data?) {
    if (data) {
      // no event should trigger as it is close by default on success of submit
    } else {
      this.apiService.logGAEvent(
        'uaevent',
        'Content',
        'Click::Customer_Details',
        'postcode Edit close'
      );
    }
    this.modalController.dismiss(data);
  }
}
