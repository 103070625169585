<div class="one3d-confirmation-modal-wrapper ">
  <h1 class="one3d-confirmation-modal-title">
    {{ msg.header | translate | uppercase }}
  </h1>
  <p class="one3d-confirmation-modal-para">
    {{ msg.content | translate }}
  </p>

  <div class="one3d-confirmation-modal-btn-wrapper">
    <button
      (click)="cancel()"
      class="one3d-confirmation-modal-btn one3d-confirmation-modal-btn-left"
    >
      {{ msg.cancelText | translate }}
    </button>
    <button
      (click)="continue()"
      class="one3d-confirmation-modal-btn one3d-confirmation-modal-btn-right"
    >
      {{ msg.confirmText  | translate}}
    </button>
  </div>
</div>
