<ion-content>
  <div class="one3d-internet-wrapper">
    <div class="one3d-internet-container">
      <div class="one3d-internet-icon">
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M55.7324 36.8677C58.4628 38.2002 61.0159 39.8687 63.3324 41.8343" stroke="#487f70" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16.666 41.8339C21.6063 37.7033 27.5523 34.9546 33.8993 33.8672" stroke="#487f70" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M35.6992 16.833C42.8406 16.2575 50.0247 17.1268 56.8227 19.389C63.6207 21.6512 69.8932 25.2599 75.2659 29.9996" stroke="#487f70" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M4.73242 29.9984C9.35631 25.9113 14.6581 22.6625 20.3991 20.3984" stroke="#487f70" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M28.4336 53.7017C31.8176 51.2975 35.8658 50.0059 40.0169 50.0059C44.168 50.0059 48.2163 51.2975 51.6003 53.7017" stroke="#487f70" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M40 66.6665H40.0333" stroke="#487f70" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M3.33398 3.3335L76.6673 76.6668" stroke="#487f70" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <h1 translate> NETWORK_CONNECTIVITY.NO_INTERNET_CONNECTION </h1>
      <p translate> NETWORK_CONNECTIVITY.NOTE </p>
    </div>
  </div>
</ion-content>
