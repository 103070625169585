import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/API/api.service';
import { CachingService } from 'src/app/services/cache/caching.service';
import { GlobalService } from 'src/app/services/global/global.service';

@Component({
  selector: 'app-buildname-modal',
  templateUrl: './buildname-modal.component.html',
  styleUrls: ['./buildname-modal.component.scss'],
})
export class BuildnameModalComponent implements OnInit {
  @Input() buttonText: any;
  @Input() action: any;
  buildNameForm: UntypedFormGroup;
  constructor(
    private modalController: ModalController,
    private formBuilder: UntypedFormBuilder,
    private cachingService: CachingService,
    public globalService: GlobalService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.setBuildNameForm();
  }

  setBuildNameForm() {
    this.buildNameForm = this.formBuilder.group({
      buildName: [
        null,
        {
          validators: [Validators.required],
        },
      ],
    });
    if (this.globalService.buildName) {
      this.buildNameForm
        .get('buildName')
        .setValue(this.globalService.buildName);
    }
  }

  closeModal() {
    this.modalController.dismiss(false);
  }
  proceedToSaveShare() {
    this.cachingService.setDataInSession(
      'buildName',
      this.buildNameForm.get('buildName').value
    );
    this.globalService.buildName = this.buildNameForm.get('buildName').value;
    this.modalController.dismiss(true);
    if (this.action == 'save') {
      this.apiService.logGAEvent('uaevent', 'Content', 'Click', 'Saved');
    }
  }
}
