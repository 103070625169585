<ion-app>
  <ion-router-outlet
    [hidden]="orientationError || browserNotSupported"
  ></ion-router-outlet>
  <orientation-modal
    [errorFor]="orientationErrorType"
    class="full-page-error"
    [hidden]="!orientationError || browserNotSupported"
  ></orientation-modal>
  <browser-support-screen
    [hidden]="orientationError || !browserNotSupported"
    class="full-page-error"
  ></browser-support-screen>
</ion-app>
