<div class="one3d-login-popup-wrapper">
  <div class="one3d-login-header">
    <h4 class="one3d-login-header-title one3d-main-title">
      <div class="one3d-close-popup">
        <ion-icon name="close" (click)="closeModal()"></ion-icon>
      </div>
      {{ "SAVE_SHARE.SAVE_SHARE" | translate }}
    </h4>
    <p class="one3d-login-header-para one3d-main-copy">
      {{ "SAVE_SHARE.CONFIGURATION_SAVED" | translate }}.
      <b>{{ "SAVE_SHARE.DOWNLOAD_PDF" | translate }}</b>
      {{ "SAVE_SHARE.OR" | translate }}
      <b>{{ "SAVE_SHARE.SAVE_THE_URL" | translate }}</b>
      {{ "SAVE_SHARE.CONFIG_SUMMARY" | translate }}
    </p>
  </div>
  <div>
    <div class="one3d-mail-button-outer">
      <div class="one3d-mail-button-row" *ngIf="enableMail">
        <button class="one3d-mail-button one3d-active-button">
          <span
            ><ion-img
              src="assets/img/icons/one3d-mail-icon.svg"
            ></ion-img></span
          ><span>{{ "SAVE_SHARE.MAIL" | translate }}</span>
        </button>
      </div>
      <div class="one3d-mail-button-row">
        <button class="one3d-mail-button" [disabled]="disableCta" (click)="downloadConfiguration()">
          <span
            ><ion-img
              src="assets/img/icons/one3d-download-icon.svg"
            ></ion-img></span
          ><span>{{ "SAVE_SHARE.DOWNLOAD" | translate }}</span>
        </button>
      </div>
    </div>

    <form [formGroup]="mailForm">
      <div class="one3d-login-input-number-row" *ngIf="enableMail">
        <div class="one3d-input-box">
          <input
            class="one3d-login-input-number one3d-full-width"
            [placeholder]="'SAVE_SHARE.ENTER_MAIL' | translate"
            type="text"
            formControlName="email"
            maxlength="100"
            (ngModelChange)="hideEmailSuccessMsg()"
          />
          <div class="one3d-share-button-row">
            <button
              class="one3d-share-button"
              (click)="shareConfiguration()"
              [disabled]="!mailForm.valid"
            >
              {{ "SAVE_SHARE.SHARE" | translate }}
            </button>
          </div>
        </div>
        <p class="one-3d-error-msg">{{ formErrors.email }}</p>

        <div
          class="one3d-ss-popup-footer one3d-success-message"
          *ngIf="showEmailSuccessMsg"
        >
          <ion-img src="assets/img/icons/one3d-radio.svg"></ion-img>
          <span>
            {{ "SAVE_SHARE.SHARED_SUCCESSFULLY" | translate }}
          </span>
        </div>
      </div>

      <div class="one3d-login-input-number-row">
        <div class="one3d-dotted-line"></div>
      </div>

      <div class="one3d-login-input-number-row" *ngIf="enableMail">
        <div class="one3d-desk-info">
          <p>
            FCA Australia Pty Ltd (FCA) respects your privacy. We will only use
            the information you provide for the purposes of processing and
            fulfilling your request or enquiry. We may disclose your information
            to an authorised FCA dealer, our related companies and/or third
            parties associated with us for these purposes. If you have consented
            on this form, we may also use and disclose your information to send
            you marketing material about products and services which may be of
            interest to you. If you have asked to be contacted by a dealer, we
            may also disclose your information to an FCA dealer for that
            purpose. Our
            <a
              href="https://www.jeep.com.au/owners/privacy.html"
              target="_blank"
              >Privacy Policy</a
            >
            contains further details regarding how you can access or correct
            information we hold about you, how you can make a privacy related
            complaint, how that complaint will be dealt with and the extent to
            which your information may be disclosed to overseas recipients.
          </p>
        </div>
        <div class="one3d-special-offer-check one3d-checkbox-button">
          <input
            id="specialOffers"
            type="checkbox"
            name="check"
            value="specialOffers"
            checked=""
          />
          <label for="specialOffers"
            >I wish to receive special offers, events and news from
            <span>Jeep Australia</span></label
          >
        </div>
      </div>

      <div
        class="one3d-login-input-number-row one3d-login-input-number-row-last"
      >
        <div class="one3d-copy-input-box">
          <input
            id="pdf-url-input"
            type="text"
            readonly
            class="one3d-login-input-number one3d-full-width one3d-copy-input"
            [placeholder]="'SAVE_SHARE.SUMMARY_PDF_URL' | translate"
          />
          <div class="one3d-share-button-row">
            <button class="one3d-copy-url-button" [disabled]="disableCta" (click)="copyURL()">
              <span
                ><ion-img
                  src="assets/img/icons/one3d-copy-url-icon.svg"
                ></ion-img></span
              ><span>{{ "SAVE_SHARE.COPY_URL" | translate }}</span>
            </button>
          </div>
          <div
            class="one3d-ss-popup-footer one3d-success-message"
            *ngIf="showSuccessCopyLink"
          >
            <ion-img src="assets/img/icons/one3d-radio.svg"></ion-img>
            <span>
              {{ "SAVE_SHARE.LINK_COPIED" | translate }}
            </span>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
