<configurator-header></configurator-header>
<ion-content scrollY="false">
  <div class="one3d-profile-wrapper">
    <div class="one3d-profile-header one3d-custom-border-bottom">
      <h4 class="one3d-profile-header-title">
        {{'PROFILE.HELLO' | translate}}
        <span class="one3d-color-black">{{
          globalService.customerInfo?.full_name
        }}</span>
      </h4>
      <button (click)="closeProfilePanel()">
        <span
          ><img
            src="assets/img/icons/one3d-close-icon.svg"
            alt="close page icon"
        /></span>
      </button>
    </div>

    <div class="one3d-profile-header one3d-custom-border-bottom">
      <h4 class="one3d-profile-header-title">
        {{'PROFILE.SAVED_CONFIGURATION' | translate}} :
        <span class="one3d-color-black">{{ allConfigs.length }}</span>
      </h4>
      <button class="one3d-profile-header-btn" (click)="logout()">
        {{'PROFILE.LOGOUT' | translate}}<span
          ><img src="assets/img/icons/one3d-logout-icon.svg" alt="logout-icon"
        /></span>
      </button>
    </div>

    <!--All Configurations -->
    <div
      class="one3d-cc-wrapper one3d-scrollable-wrapper-custom"
      *ngIf="allConfigs.length"
    >
      <div class="one3d-cc" *ngFor="let config of allConfigs">
        <div class="one3d-cc-img-wrapper">
          <!-- config.image_url needs to be added once image_url is not null -->
          <img
            class="one3d-cc-img"
            [src]="config.car_image_name"
            alt="car-image"
          />
          <!-- <img class="one3d-cc-img" src="assets/img/one3d-config-card-car.png" alt="car-image" /> -->
          <!-- <span class="one3d-cc-del-icon" (click)="deleteConfiguration(config)">
          <ion-icon class="one3d-delete-icon" name="trash-outline"></ion-icon>
        </span> -->
        </div>
        <h4 class="one3d-cc-car-title">
          {{ config.build_name }}
        </h4>
        <div class="one3d-cc-details">
          <ion-row class="one3d-cc-details-row">
            <ion-col
              size="6"
              class="one3d-cc-details-col"
              *ngIf="config.created_at == config.updated_at"
            >
              <span class="one3d-cc-details-upper-text">{{
                config.model_name
              }}</span>
              <span class="one3d-cc-details-lower-text"
                >{{'PROFILE.CREATED_ON' | translate}} : {{ config.created_at }}</span
              >
            </ion-col>
            <ion-col
              size="6"
              class="one3d-cc-details-col"
              *ngIf="config.created_at != config.updated_at"
            >
              <span class="one3d-cc-details-upper-text">{{
                config.model_name
              }}</span>
              <span class="one3d-cc-details-lower-text"
                >{{'PROFILE.UPDATED_ON' | translate}} : {{ config.updated_at }}</span
              >
            </ion-col>
            <ion-col size="6" class="one3d-cc-details-col">
              <span class="one3d-cc-details-upper-text"
                >
                {{
                  globalService.currencyFormatter(config.showroom_price)
                }}*</span
              >
              <span class="one3d-cc-details-lower-text">{{'PROFILE.EX_SHOWROOM_PRICE' | translate}}</span>
            </ion-col>
          </ion-row>
          <ion-row class="one3d-cc-details-row">
            <ion-col size="6" class="one3d-cc-details-col">
              <span class="one3d-cc-details-upper-text">{{
                config.variant_name
              }}</span>
              <span class="one3d-cc-details-lower-text">{{'PROFILE.TRIM' | translate}}</span>
            </ion-col>
            <ion-col size="6" class="one3d-cc-details-col">
              <span class="one3d-cc-details-upper-text"
                >2.0L {{ config.fuel_type }}
                {{ config.transmission_code }}</span
              >
              <span class="one3d-cc-details-lower-text"
                >{{'PROFILE.ENGINE_TRANSMISSION' | translate}}</span
              >
            </ion-col>
          </ion-row>
          <ion-row class="one3d-cc-details-row">
            <ion-col size="6" class="one3d-cc-details-col">
              <span class="one3d-cc-details-upper-text">{{
                config.drive_type
              }}</span>
              <span class="one3d-cc-details-lower-text">{{'PROFILE.DRIVE_TYPE' | translate}}</span>
            </ion-col>
            <ion-col size="6" class="one3d-cc-details-col">
              <span class="one3d-cc-details-upper-text"
                >{{
                  config.acc_added_amount > 0
                    ? globalService.currencyFormatter(config.acc_added_amount)
                    : "0"
                }}*</span
              >
              <span class="one3d-cc-details-lower-text">{{'PROFILE.ACCESSORY_PRICE' | translate}}</span>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col (click)="downloadPDF()" size="6">
              <a
                href="{{ config.downloadPdf }}"
                target="_blank"
                class="one3d-cc-btn one3d-cc-btn-left"
                ><img
                  src="assets/img/icons/one3d-download-icon.svg"
                  alt="download-icon"
                />
                {{'PROFILE.PDF' | translate}}</a
              >
            </ion-col>

            <ion-col size="6">
              <button
                class="one3d-cc-btn one3d-cc-btn-right"
                (click)="modalToConfirmEditConfiguration(config)"
              >
              {{'PROFILE.EDIT' | translate}}
              </button>
            </ion-col>
          </ion-row>
        </div>
      </div>
      <ion-row *ngIf="pageCount != totalPages">
        <ion-col size="12" class="ion-text-center">
          <ion-text color="dark" (click)="loadMore()">
            {{'PROFILE.LOAD_MORE_CONFIGURATIONS' | translate}}
          </ion-text>
        </ion-col>
      </ion-row>
    </div>

    <!-- If no configuration found -->
    <!-- Empty screen design not completed -->
    <div class="one3d-cc-no-config" *ngIf="searchResult && !allConfigs.length">
      <p class="one3d-cc-no-config-title">
       {{'PROFILE.NOT_SAVED' | translate}}
      </p>
      <button
        class="one3d-cc-save-config-btn"
        (click)="saveCurrentConfiguration()"
      >
      {{'PROFILE.SAVE_CURRENT_CONFIGURATION' | translate}}
      </button>
    </div>

    <div class="one3d-profile-bottom one3d-placeAtBottom">
      <button
        class="one3d-profile-bottom-btn"
        (click)="modalToConfirmNewConfiguration()"
      >
      {{'PROFILE.CREATE_NEW_CONFIGURATION' | translate}}
      </button>
    </div>
  </div>
</ion-content>
