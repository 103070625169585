import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'orientation-modal',
  templateUrl: './orientation-modal.component.html',
  styleUrls: ['./orientation-modal.component.scss'],
})
export class OrientationModalComponent implements OnInit {
  @Input() errorFor;
  constructor() {}
  ngOnInit() {}
}
