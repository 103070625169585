<ion-content scrollY="false">
  <div class="one3d-orientation-wrapper">
    <div class="one3d-orientation-container">
      <div class="one3d-orientation-icon">
        <img src="assets/img/one3d-rotate.svg" alt="rotate landscape image" />
      </div>
      <p class="one3d-orientation-para" *ngIf="errorFor == 'ipad'" translate>
        ORIENTATION_MODAL.LANDSCAPE
      </p>
      <p class="one3d-orientation-para-mobile" *ngIf="errorFor == 'mobile'" translate>
        ORIENTATION_MODAL.PORTRAIT
      </p>
    </div>
  </div>
</ion-content>