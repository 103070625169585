import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CachingService } from 'src/app/services/cache/caching.service';
import { GlobalService } from 'src/app/services/global/global.service';
import { EventService } from 'src/app/services/Events/events.service';
import { LoginComponent } from '../login/login.component';
import { ProfileComponent } from '../profile/profile.component';
import { ApiService } from 'src/app/services/API/api.service';
import { Subscription } from 'rxjs';
import { PostcodeComponent } from '../postcode/postcode.component';
import { TestDriveFormComponent } from '../test-drive-form/test-drive-form.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'configurator-header',
  templateUrl: './configurator-header.component.html',
  styleUrls: ['./configurator-header.component.scss'],
})
export class ConfiguratorHeaderComponent implements OnInit {
  viewProfile = false;
  loginClicked = false;
  subscription: Subscription;
  loginPopupShown = false;
  loginHeaderText = 'Login';
  isFeaturePlaying = false;

  @Output() editConfig = new EventEmitter();
  constructor(
    private modalController: ModalController,
    public globalService: GlobalService,
    private cachingService: CachingService,
    private events: EventService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    // this.getCustomerInfo();
    this.getUpdatePostcode();
    this.subscription = this.events.subscribe('events', (data) => {
      this.subscribeToEvents(data);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  subscribeToEvents(data) {
    const key = Object.keys(data)[0];
    switch (key) {
      case 'featureIsPlaying':
        this.isFeaturePlaying = data.featureIsPlaying;
        break;
      case 'firstLoadComplete':
        // for now first take postcode input, not after load complete
        // if (!this.globalService.postcode) {
        //   console.log('timer');
        //   const audio = new Audio('assets/sounds/notification.mp3');
        //   audio.play();
        //   this.openLoginModal();
        // }
        break;
      case 'showLoginAutoPopup':
        setTimeout(() => {
          console.log('auto');

          if (!this.loginClicked && !this.globalService.customerInfo) {
            const audio = new Audio('assets/sounds/notification.mp3');
            audio.play();
            // this.events.publish('events', { resetToDefault: null });
            // this.openLoginModal('autoPopup');
          }
        }, 10000);
        break;
      case 'updatedPostcode':
        this.getUpdatePostcode();
        break;
      default:
        break;
    }
  }

  async getCustomerInfo() {
    this.globalService.customerInfo =
      await this.cachingService.getDataFromLocal('userDetails');
    console.log('customerInfo', this.globalService.customerInfo);
    if (this.globalService.customerInfo) {
      this.apiService.updateReportingData(
        'cuId',
        this.globalService.customerInfo.customer_id
      );
    }
  }

  async getUpdatePostcode() {
    const postcode = await this.cachingService.getDataFromLocal('p_cd');
    if (postcode) {
      console.log('postcode in header', postcode);
      this.globalService.postcode = postcode;
    } else {
      console.log('no postcode available');
    }
  }

  async openLoginModal(autoPopup?) {
    const modal = await this.modalController.create({
      component: PostcodeComponent,
      componentProps: {
        contentData: {
          header: 'CONFIGURATOR_HEADER_MSG.HEADER',
          msgContent: 'CONFIGURATOR_HEADER_MSG.CONTENT',
        },
        type: 'old',
      },
      backdropDismiss: false,
      cssClass: 'one3d-login-modal ' + ' auto-height',
      animated: false,
    });

    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click::Customer_Details',
      'postcode Edit'
    );
    this.apiService.sendReportingData({
      eName: 'form_view',
      eCat: 'lead_form',
      eAct: 'proceed_to_enter',
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    data ? this.globalService.updateUIPrices(data) : null;
  }

  async openProfilePanel() {
    if (window.location.pathname.includes('visualizer')) {
      return;
    }

    this.events.publish('events', { resetToDefault: null });
    if (this.globalService.activeTab !== 'Profile') {
      this.apiService.logGAEvent(
        'uaevent',
        'Content',
        'Redirection::Internal',
        'User profile'
      );
      const isModalOpened = await this.modalController.getTop();
      if (isModalOpened) {
        this.modalController.dismiss();
      }
      const modal = await this.modalController.create({
        component: ProfileComponent,
        backdropDismiss: false,
        animated: false,
        cssClass: 'one3d-sidePanelModal-summary',
      });
      this.events.publish('events', { viewProfile: true });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.editConfig.emit('edit');
      }
      const modalOpened = await this.modalController.getTop();
      if (modalOpened) {
        this.modalController.dismiss();
      }
      this.globalService.activeTab = 'accessories';
    }
  }

  backToVisualizer() {
    this.events.publish('events', { resetToDefault: null }); // on click of back to features, reset to default view
    // Get the current query parameters
    const queryParams = { ...this.route.snapshot.queryParams };

    // Add or update query parameters as needed
    queryParams['cc'] = 'false'; // Add or update 'cc'

    // Navigate to the same route with the updated query parameters
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      // queryParamsHandling: 'merge', // Preserve existing query parameters
    });
    this.events.publish('events', {
      backToVisualizer: null,
    });
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click',
      'back to features'
    );
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click::Set_' + this.globalService.idea,
      'back to features::Set_' + this.globalService.idea
    );
  }

  redirectToJeep() {
    window.open('https://www.jeep.com.au/', '_parent');
  }

  closeFeatureView() {
    this.events.publish('close_mobile_feature_view', true);
  }
}
