<div class="one3d-login-popup-wrapper">
  <div class="one3d-close-popup">
    <ion-icon (click)="closeModal()" name="close"></ion-icon>
  </div>

  <div class="one3d-login-header">
    <p class="one3d-login-header-para">
      {{ 'BUILD_NAME.NOTE' | translate: { modelName: globalService.model?.model_name } }}
    </p>
  </div>
  <form [formGroup]="buildNameForm">
    <div class="one3d-login-input-number-row">
      <input class="one3d-login-input-number one3d-full-width" type="text"
      [placeholder]="'BUILD_NAME.PLACEHOLDER_EXAMPLE' | translate:{ modelName: globalService.model?.model_name }"
      formControlName="buildName" />
    </div>

    <button class="one3d-login-bottom-btn one3d-login-submit-btn" [disabled]="!buildNameForm.valid"
      (click)="proceedToSaveShare()">
      {{ buttonText  | translate }}
    </button>
  </form>
</div>