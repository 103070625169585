export const environment = {
  production: true,
  disableLogging: true,
  encryptionPassword: 'UcK*hCW(7qcf{N3d',
  fmKey: 'KSGygdCfLVjbaQCPfHqwtY',
  // PROD
  // apiUrl: 'https://prod-iap-jeep-aust.one3d.in/',
  apiUrl: 'https://configuratorapi.jeep.com.au/',
  assetsUrl: 'https://d1e07brvmnlvvu.cloudfront.net/',
  jeepUrl: 'https://www.jeep.com.au',
  carExchangeUrl:'https://jeepselectedforyou.in/used-car-valuation/?token=WS1ngjLGRSab13D8jTdU6dTrh',
  function_js: 'one3d_functions.min.js', //min.js will be extension for functions file confirmed with @sundaram 14/11/23

};
