<div class="one3d-login-popup-wrapper">
  <div class="one3d-close-popup" *ngIf="globalService.postcode">
    <ion-icon name="close" (click)="closeModal()"></ion-icon>
  </div>

  <div class="one3d-login-header">
    <h4 class="one3d-login-header-title">
      {{ contentData.header | translate }}
    </h4>
    <p class="one3d-login-header-para">
      {{ contentData.msgContent | translate }}
    </p>
  </div>
  <div>
    <form [formGroup]="postcodeForm">
      <div class="one3d-login-input-number-row">
        <input
          class="one3d-login-input-number one3d-full-width"
          type="tel"
          id="postcode"
          maxlength="4"
          formControlName="postcode"
          [placeholder]="'POSTCODE.PLACEHOLDER' | translate"
          (keypress)="globalService.numberOnlyValidation($event)"
        />
      </div>
      <p class="one3d-login-input-error">
        {{ formErrors.postcode | translate }}
      </p>
    </form>
    <button
      class="one3d-login-bottom-btn one3d-login-submit-btn"
      [disabled]="!postcodeForm.controls.postcode.valid"
      (click)="submitPostcode()"
      translate
    >
      POSTCODE.SUBMIT
    </button>
  </div>
</div>
